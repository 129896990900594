import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useOnline from '@rooks/use-online';
import './connectionStatus.css';

export default function ConnectionStatus() {
  const online = useOnline();
  const { connected, reconnectAt } = useSelector((state) => state.poker);
  const [flipFlop, setFlipFlop] = useState(0);

  // Re-render every second to update timer
  useEffect(() => {
    const interval = window.setInterval(() => {
      if (!connected) {
        setFlipFlop((flipFlop + 1) % 2);
      }
    }, 1000);

    return () => window.clearInterval(interval);
  }, [connected, flipFlop]);

  if (!online) {
    return (
      <div className="connectionStatus offline">Connection offline</div>
    );
  }

  if (!connected && reconnectAt >= 0) {
    const remainingTime = Math.ceil((reconnectAt - Date.now()) / 1000);

    return (
      <div className="connectionStatus disconnected">Connection lost – Reconnecting in {remainingTime}s</div>
    );
  }

  return null;
}
