import { Reducer } from 'redux';
import { CardValue } from 'pirate-poker-common';
import { StoreListener } from './listenerMiddleware';

function setStoredValue(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    // noop
  }
}

function getStoredValue(key: string) {
  try {
    return localStorage.getItem(key) || undefined;
  } catch (error) {
    return undefined;
  }
}

// State
type User = {
  clientId: number;
  name: string;
  avatar: string;
  color: string;
};
export interface PokerState {
  users: User[];
  votes: { [index: number]: CardValue };
  voteCounts: { [index: number]: number };
  topic: string;
  topicSender?: number;
  connected: boolean;
  reconnectAt: number;
  joined: boolean;
  revealed: boolean;
  clientId?: number;
  name?: string;
  avatar?: string;
  autoJoin: boolean;
}

// Types
const SET_AUTO_JOIN = 'SET_AUTO_JOIN';
const ADD_USER = 'ADD_USER';
const REMOVE_USER = 'REMOVE_USER';
const CONNECTED = 'CONNECTED';
const RECONNECT = 'RECONNECT';
const REGISTRATION = 'REGISTRATION';
const JOIN = 'JOIN';
const LEAVE = 'LEAVE';
const VOTE = 'VOTE';
const SET_TOPIC = 'SET_TOPIC';
const CLEAR = 'CLEAR';
const REVEAL = 'REVEAL';

// Actions
type SetAutoJoinAction = {
  type: typeof SET_AUTO_JOIN;
  value: boolean;
};
type AddUserAction = {
  type: typeof ADD_USER;
  clientId: number;
  name: string;
  avatar: string;
  color: string;
};
type RemoveUserAction = {
  type: typeof REMOVE_USER;
  clientId: number;
};
type ConnectedAction = {
  type: typeof CONNECTED;
};
type ReconnectAction = {
  type: typeof RECONNECT;
  reconnectAt: number;
};
type RegistrationAction = {
  type: typeof REGISTRATION;
  clientId: number;
};
type JoinAction = {
  type: typeof JOIN;
  name: string;
  avatar: string;
};
type LeaveAction = {
  type: typeof LEAVE;
};
type VoteAction = {
  type: typeof VOTE;
  clientId: number;
  value: CardValue;
};
type SetTopicAction = {
  type: typeof SET_TOPIC;
  clientId: number;
  value: string;
};
type ClearAction = {
  type: typeof CLEAR;
  clientId: number;
};
type RevealAction = {
  type: typeof REVEAL;
  clientId: number;
};

export type KnownAction =
  | SetAutoJoinAction
  | AddUserAction
  | RemoveUserAction
  | ConnectedAction
  | ReconnectAction
  | RegistrationAction
  | JoinAction
  | LeaveAction
  | VoteAction
  | SetTopicAction
  | ClearAction
  | RevealAction;

// Action Creators
export const actions = {
  enableAutoJoin: (): SetAutoJoinAction => ({
    type: SET_AUTO_JOIN,
    value: true,
  }),
  disableAutoJoin: (): SetAutoJoinAction  => ({
    type: SET_AUTO_JOIN,
    value: false,
  }),
  addUser: (clientId: number, name: string, avatar: string, color: string): AddUserAction => ({
    type: ADD_USER,
    clientId,
    name,
    avatar,
    color,
  }),
  removeUser: (clientId: number): RemoveUserAction => ({
    type: REMOVE_USER,
    clientId,
  }),
  connected: (): ConnectedAction => ({
    type: CONNECTED,
  }),
  reconnect: (reconnectAt: number): ReconnectAction => ({
    type: RECONNECT,
    reconnectAt,
  }),
  registration: (clientId: number): RegistrationAction => ({
    type: REGISTRATION,
    clientId,
  }),
  join: (name: string, avatar: string): JoinAction => ({
    type: JOIN,
    name,
    avatar,
  }),
  leave: (): LeaveAction => ({
    type: LEAVE,
  }),
  vote: (clientId: number, value: CardValue): VoteAction => ({
    type: VOTE,
    clientId,
    value,
  }),
  setTopic: (clientId: number, value: string): SetTopicAction => ({
    type: SET_TOPIC,
    clientId,
    value,
  }),
  clear: (clientId: number): ClearAction => ({
    type: CLEAR,
    clientId,
  }),
  reveal: (clientId: number): RevealAction => ({
    type: REVEAL,
    clientId,
  }),
};

// Store Listener
export const listener: StoreListener<KnownAction> = {
  [SET_AUTO_JOIN]: ({ value }) => {
    setStoredValue('poker-autoJoin', value ? '1' : '0');
  },
  [JOIN]: ({ name, avatar }) => {
    setStoredValue('poker-name', name);
    setStoredValue('poker-avatar', avatar);
  },
};

// Reducer
const initialState: PokerState = {
  users: [],
  votes: {},
  voteCounts: {},
  topic: '',
  topicSender: undefined,
  connected: false,
  reconnectAt: -1,
  joined: false,
  revealed: false,
  clientId: undefined,
  name: getStoredValue('poker-name'),
  avatar: getStoredValue('poker-avatar'),
  autoJoin: getStoredValue('poker-autoJoin') === '1',
};

export const reducer: Reducer<PokerState, KnownAction> = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTO_JOIN:
      return {
        ...state,
        autoJoin: action.value,
      };
    case ADD_USER:
      return {
        ...state,
        users: [
          ...state.users,
          {
            clientId: action.clientId,
            name: action.name,
            avatar: action.avatar,
            color: action.color,
          },
        ],
      };

    case REMOVE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.clientId !== action.clientId),
      };

    case CONNECTED:
      return {
        ...state,
        connected: true,
      };

    case RECONNECT:
      return {
        ...state,
        connected: false,
        reconnectAt: action.reconnectAt,
      };

    case REGISTRATION:
      return {
        ...state,
        clientId: action.clientId,
      };

    case JOIN:
      return {
        ...state,
        joined: true,
        name: action.name,
        avatar: action.avatar,
      };

    case LEAVE:
      return {
        ...state,
        joined: false,
        autoJoin: false,
      };

    case VOTE:
      return {
        ...state,
        votes: {
          ...state.votes,
          [action.clientId]: action.value,
        },
        voteCounts: {
          ...state.voteCounts,
          [action.clientId]: state.voteCounts[action.clientId]
            ? state.voteCounts[action.clientId] + 1
            : 1,
        },
      };

    case SET_TOPIC:
      return {
        ...state,
        topic: action.value,
        topicSender: action.clientId,
      };

    case CLEAR:
      return {
        ...state,
        votes: {},
        topic: '',
        topicSender: undefined,
        revealed: false,
      };

    case REVEAL:
      return {
        ...state,
        revealed: true,
      };

    default:
      // The following line guarantees that every action in the KnownAction
      // union has been covered by a case above
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = action;
      // eslint-disable-next-line no-void
      void exhaustiveCheck;
  }

  return state;
};
