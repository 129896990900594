import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducers, listeners } from './store';
import listenerMiddleware from './store/listenerMiddleware';

export default function configureStore(preloadedState = {}) {
  const rootReducer = combineReducers({
    ...reducers,
  });

  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        listenerMiddleware(listeners)
      )
    )
  );

  return store;
}
