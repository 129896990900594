import React from 'react';
import "./pokerChip.css";

type PokerChipProps = {
  value: string;
};

export default function PokerChip({ value }: PokerChipProps) {
  return (
    <div className="pokerchip">
      <div className="pokerchipText">{value}</div>
    </div>
  );
}
