import React, { useRef, useLayoutEffect } from 'react';
import ArcText from 'arc-text';
import './pageTitle.css';

type PageTitleProps = {
  children: string;
};

export default function PageTitle({ children }: PageTitleProps) {
  const ref = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    let text: ArcText;

    if (ref.current) {
      text = new ArcText(ref.current)
      text.forceWidth(true);
      text.forceHeight(true);
      text.arc(500);
      text.direction(1);
    };

    return () => {
      text?.destroy();
    };
  }, []);

  return (
    <h1 className="pageTitle" ref={ref}>{children}</h1>
  );
}
