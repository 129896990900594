import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SocketContext } from '../services/poker';
import PokerTable from './PokerTable';
import PokerChip from './PokerChip';
import HandOfCards from './HandOfCards';
import './pokerSession.css';
import PokerSeat from './PokerSeat';
import { AvatarValue } from '../assets/avatars';

export default function PokerSession() {
  const socketService = useContext(SocketContext);
  const {
    topic: backendTopic,
    topicSender,
    users,
    votes,
    voteCounts,
    revealed,
    clientId,
  } = useSelector((state) => state.poker);
  const [topic, setTopic] = useState(backendTopic);
  const [topicLastChangedBy, setTopicLastChangedBy] = useState<string>();
  const pointList = Object.values(votes).flatMap((value) =>
    // Don't consider non-number votes
    typeof value === 'number' ? value : [],
  );
  // Take the value in the middle. Favor the higher value in case of an even count of votes.
  const meanPoints = pointList.sort()[Math.ceil(pointList.length / 2) - (pointList.length % 2)];

  // Update "topic last changed by" line when sender ID changes, but keep old
  // name in case user is not around anymore
  useEffect(() => {
    setTopicLastChangedBy(
      topicSender !== undefined
        ? users.find((user) => user.clientId === topicSender)?.name || topicLastChangedBy
        : undefined,
    );
  }, [users, topicSender, topicLastChangedBy]);

  const handleTopicChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTopic(event.target.value);
      socketService?.topic(event.target.value);
    },
    [socketService],
  );

  useEffect(() => {
    if (
      (topic !== backendTopic && topicSender !== undefined && topicSender !== clientId) ||
      (backendTopic === '' && topicSender === undefined)
    ) {
      setTopic(backendTopic);
    }
  }, [topic, backendTopic, topicSender, clientId]);

  return (
    <div className="pokerSession">
      <div className="pokerSessionHeader">
        <div className="headlineWrap">
          <h2>
            Topic
            {topicLastChangedBy && (
              <small className="topicLastChangedBy">Last changed by {topicLastChangedBy}</small>
            )}
          </h2>
          <div className="buttonSet">
            <button onClick={() => socketService?.clear()}>Clear Votes</button>
            <button className="primary" onClick={() => socketService?.reveal()}>
              Reveal Votes
            </button>
          </div>
        </div>
        <input type="text" name="topic" value={topic} onChange={handleTopicChange} />
      </div>
      <PokerTable
        center={revealed && !isNaN(meanPoints) ? <PokerChip value={String(meanPoints)} /> : undefined}
        seats={users.map(({ clientId, name, avatar, color }) => (
          <PokerSeat
            key={`${clientId}-${voteCounts[clientId]}`}
            name={name}
            avatar={avatar as AvatarValue}
            color={color}
            value={votes[clientId] ? String(votes[clientId]) : undefined}
            revealed={revealed || votes[clientId] === '☕'} // Always reveal coffee break
          />
        ))}
        frontSeatIndex={users.findIndex((user) => user.clientId === clientId)}
      />
      <HandOfCards selectedValue={clientId && votes[clientId]} />
    </div>
  );
}
