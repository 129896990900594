import React from 'react';
import clsx from 'clsx';
import './playingCard.css';

type PlayingCardProps = JSX.IntrinsicElements['div'] & {
  className?: string;
  face?: 'front' | 'back';
  value?: string;
};

export default function PlayingCard({ className, face = 'front', value, ...others }: PlayingCardProps) {
  return (
    <div className={clsx('playingCard', face, className)} {...others}>
      <div className="playingCardFace backFace" />
      <div className="playingCardFace frontFace">{value}</div>
    </div>
  );
}
