import React from 'react';
import { useSelector } from 'react-redux';
import ConnectionStatus from './ConnectionStatus';
import PageTitle from './PageTitle';
import LeaveButton from './LeaveButton';
import JoinForm from './JoinForm';
import PokerSession from './PokerSession';

export default function App() {
  const { joined } = useSelector((state) => state.poker);

  return (
    <>
      <ConnectionStatus />
      <div className="container">
        <PageTitle>Pirate Poker</PageTitle>
        {joined && <LeaveButton />}
        {!joined && <JoinForm />}
        {joined && <PokerSession />}
      </div>
    </>
  );
}
