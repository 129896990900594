import { createContext } from 'react';
import { Store } from 'redux';
import { KnownAction, actions as pokerActions } from '../store/poker';
import { ApplicationState } from '../store';
import { CardValue, Message, ServerMessage } from 'pirate-poker-common';

const RECONNECT_INTERVAL_TIME = 5000;

const PLAYER_COLORS = [
  'red',
  'blue',
  'teal',
  'purple',
  'yellow',
  'orange',
  'green',
  'pink',
  'grey',
  'lightblue',
  'darkgreen',
  'brown',
];

let socket: WebSocket | undefined;
let reconnectTimeout: number | undefined;

function sendMessage(message: Message) {
  if (!socket || socket.readyState !== WebSocket.OPEN) {
    throw new Error('WebSocket connection has to be opened first');
  }

  const data = JSON.stringify(message);
  socket.send(data);
}

type SocketService = {
  openConnection: () => void;
  closeConnection: () => void;
  reconnect: () => void;
  join: (name: string, avatar: string) => void;
  leave: () => void;
  topic: (value: string) => void;
  vote: (value: CardValue) => void;
  clear: () => void;
  reveal: () => void;
};

export const SocketContext = createContext<SocketService | null>(null);

export default (store: Store<ApplicationState, KnownAction>): SocketService => ({
  openConnection() {
    if (socket && socket.readyState === WebSocket.OPEN) {
      throw new Error('WebSocket connection is already open');
    }

    try {
      const { protocol, hostname, port } = window.location;
      const socketProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
      const socketUrl = process.env.REACT_APP_WS_SERVER || `${socketProtocol}//${hostname}:${port}`;
      socket = new WebSocket(socketUrl);
      socket.addEventListener('open', () => {
        store.dispatch(pokerActions.connected());
        window.clearInterval(reconnectTimeout);
      });
      socket.addEventListener('close', () => {
        this.reconnect();
      });
      socket.addEventListener('message', (event) => {
        const pokerState = store.getState().poker;
        const message: ServerMessage = JSON.parse(event.data);

        switch (message.type) {
          case 'registration':
            store.dispatch(pokerActions.registration(message.clientId));
            break;

          case 'join':
            const takenColors = pokerState.users.map((user) => user.color);
            const freeColors = PLAYER_COLORS.filter((potentialColor) => !takenColors.includes(potentialColor));
            const color = freeColors[0] || 'white'; // Fallback to white of no color is available
            store.dispatch(pokerActions.addUser(message.clientId, message.name, message.avatar, color));
            break;

          case 'leave':
            store.dispatch(pokerActions.removeUser(message.clientId));
            break;

          case 'vote':
            store.dispatch(pokerActions.vote(message.clientId, message.value));
            break;

          case 'topic':
            store.dispatch(pokerActions.setTopic(message.clientId, message.value));
            break;

          case 'clear':
            store.dispatch(pokerActions.clear(message.clientId));
            break;

          case 'reveal':
            store.dispatch(pokerActions.reveal(message.clientId));
            break;

          default:
            const exhaustiveCheck: never = message;
            void exhaustiveCheck;
        }
      });
    } catch (error) {
      console.error(error);
      this.reconnect();
    }
  },
  closeConnection() {
    if (socket) {
      socket.close();
      socket = undefined;
    }
  },
  reconnect() {
    reconnectTimeout = window.setTimeout(this.openConnection.bind(this), RECONNECT_INTERVAL_TIME);
    store.dispatch(pokerActions.reconnect(Date.now() + RECONNECT_INTERVAL_TIME));
  },
  join(name, avatar) {
    sendMessage({
      type: 'join',
      name,
      avatar,
    });
  },
  leave() {
    sendMessage({
      type: 'leave',
    });
  },
  topic(value) {
    store.dispatch(pokerActions.setTopic(store.getState().poker.clientId!, value));
    sendMessage({
      type: 'topic',
      value,
    });
  },
  vote(value) {
    sendMessage({
      type: 'vote',
      value,
    });
  },
  reveal() {
    sendMessage({
      type: 'reveal',
    });
  },
  clear() {
    sendMessage({
      type: 'clear',
    });
  },
});
