import React, { useState, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import avatars, { AvatarValue } from '../assets/avatars';
import './avatarSelect.css';

type AvatarSelectProps = {
  value?: AvatarValue;
  onChange?: (value: AvatarValue) => void;
  required?: boolean;
};

function matchesFilter(filter: string, value: string) {
  const normalizedFilter = filter.toLowerCase().trim();
  const normalizedValue = value.toLowerCase().replace(/[^a-z0-9]/g, '');
  return normalizedValue.includes(normalizedFilter);
}

export default function AvatarSelect({ value, onChange, required }: AvatarSelectProps) {
  const itemsRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState('');
  const entries = Object.entries(avatars).filter(([name]) => matchesFilter(filter, name));

  useLayoutEffect(() => {
    itemsRef.current?.querySelector('.avatarSelectItem.selected')?.scrollIntoView();
  }, []);

  return (
    <div className="avatarSelect">
      <div className="avatarSelectFilter">
        <input type="text" value={filter} onChange={((event) => setFilter(event.target.value))} placeholder="Filter avatars" />
      </div>
      <div className="avatarSelectItems" ref={itemsRef}>
        {entries.map(([name, url]) => (
          <div
            key={name}
            tabIndex={0}
            className={clsx('avatarSelectItem', { selected: name === value })}
            onKeyDown={(event) => {
              if (onChange && event.keyCode === 32) {
                event.preventDefault();
                onChange(name as AvatarValue);
              }
            }}
          >
            <label className="avatarSelectItemContent" tabIndex={-1}>
              <input
                type="radio"
                name="avatar"
                value={name}
                checked={name === value}
                onChange={(event) =>
                  onChange && onChange(event.target.value as AvatarValue)
                }
                required={required}
              />
              <img src={url} alt={name} width={128} height={128} />
              <p>{name}</p>
            </label>
          </div>
        ))}
        {entries.length === 0 && (
          <div className="avatarSelectNoMatchesMessage">
            No matches
          </div>
        )}
      </div>
    </div>
  );
}
