import React, { useContext } from 'react';
import { CardValue, cardValues } from 'pirate-poker-common';
import PlayingCard from './PlayingCard';
import { SocketContext } from '../services/poker';
import './handOfCards.css';

type HandOfCardsProps = {
  selectedValue?: CardValue;
}

export default function HandOfCards({ selectedValue }: HandOfCardsProps) {
  const socketService = useContext(SocketContext);

  return (
    <div className="handOfCards">
      {cardValues.map((value) => (
        <div key={value} className="cardWrapper">
          <PlayingCard
            className={value === selectedValue ? 'selected' : undefined}
            face="front"
            value={String(value)}
            onClick={() => socketService?.vote(value)}
          />
        </div>
      ))}
    </div>
  );
}
