import React from 'react';
import avatars, { AvatarValue } from '../assets/avatars';
import PlayingCard from './PlayingCard';
import './pokerSeat.css';

type PokerSeatProps = {
  name: string;
  avatar: AvatarValue;
  color: string;
  value?: string;
  revealed: boolean;
};

export default function PokerSeat({ name, avatar, color, value, revealed }: PokerSeatProps) {
  return (
    <div className="pokerSeat">
      <img
        className="pokerSeatAvatar"
        src={avatars[avatar]}
        alt={avatar}
        style={{ borderColor: color }}
      />
      <div className="pokerSeatName">{name}</div>
      {value !== undefined && (
        <div className="pokerSeatCard">
          <PlayingCard face={revealed ? 'front' : 'back'} value={value} />
        </div>
      )}
    </div>
  );
}
