import React from 'react';
import './pokerTable.css';

type PokerTableProps = {
  center?: JSX.Element;
  seats?: JSX.Element[];
  frontSeatIndex?: number;
};

export default function PokerTable({ center, seats, frontSeatIndex }: PokerTableProps) {
  const frontSeat = frontSeatIndex !== undefined && seats ? seats[frontSeatIndex] : undefined;

  if (frontSeatIndex !== undefined && frontSeat) {
    seats?.splice(frontSeatIndex, 1);
  }

  return (
    <div className="pokerTable">
      <div className="pokerTableCenter">{center}</div>
      <div className="pokerTableSeats">{seats}</div>
      <div className="pokerTableFrontSeat">{frontSeat}</div>
    </div>
  );
}
