import image1Url from './Alien.png';
import image2Url from './Alpaca.png';
import image3Url from './Anchor.png';
import image4Url from './Anemone.png';
import image5Url from './Anglerfish.png';
import image6Url from './Banana.png';
import image7Url from './Barnacle.png';
import image8Url from './Barrel.png';
import image9Url from './Bat.png';
import image10Url from './Bee.png';
import image11Url from './Bird-Blue-Flapping.png';
import image12Url from './Bird-Blue.png';
import image13Url from './Bird-Green.png';
import image14Url from './Bird-Red.png';
import image15Url from './Blob Fish.png';
import image16Url from './Boar.png';
import image17Url from './Bomb.png';
import image18Url from './Boot.png';
import image19Url from './Bulldog.png';
import image20Url from './Bunny Pirate.png';
import image21Url from './Bunny.png';
import image22Url from './Buoy.png';
import image23Url from './Cactus.png';
import image24Url from './Cannon-Boom.png';
import image25Url from './Cannon.png';
import image26Url from './Cat.png';
import image27Url from './Chameleon.png';
import image28Url from './Characters-Felix.png';
import image29Url from './Characters-Jonas.png';
import image30Url from './Clam.png';
import image31Url from './Clown Fish.png';
import image32Url from './Cockroach.png';
import image33Url from './Coconut.png';
import image34Url from './Coil of Rope.png';
import image35Url from './Coin.png';
import image36Url from './Compass.png';
import image37Url from './Coral.png';
import image38Url from './Cow.png';
import image39Url from './Crab.png';
import image40Url from './Creature from the Black Lagoon.png';
import image41Url from './Crocodile.png';
import image42Url from './Cuttlefish.png';
import image43Url from './Daiquiri.png';
import image44Url from './Davy Jones.png';
import image45Url from './Devilfish.png';
import image46Url from './Diamond.png';
import image47Url from './Dinosaur.png';
import image48Url from './Dodo Bird.png';
import image49Url from './Dog.png';
import image50Url from './Dolphin.png';
import image51Url from './Dragon.png';
import image52Url from './Duck.png';
import image53Url from './Dynamite.png';
import image54Url from './Eagle.png';
import image55Url from './Earthworm.png';
import image56Url from './Eel.png';
import image57Url from './Elephant.png';
import image58Url from './Elvis.png';
import image59Url from './Emerald.png';
import image60Url from './Fantasia Sea Cucumber.png';
import image61Url from './Fish-Blue.png';
import image62Url from './Fish-Green.png';
import image63Url from './Fish Net.png';
import image64Url from './Fish-Pink.png';
import image65Url from './Fish-Yellow.png';
import image66Url from './Flamingo.png';
import image67Url from './Flounder.png';
import image68Url from './Frog.png';
import image69Url from './Gemstone.png';
import image70Url from './Ghost.png';
import image71Url from './Goblin Shark.png';
import image72Url from './Gorilla.png';
import image73Url from './Hammerhead Shark.png';
import image74Url from './Hermit Crab.png';
import image75Url from './Jelly Fish.png';
import image76Url from './Kangaroo.png';
import image77Url from './Killer Whale.png';
import image78Url from './Kraken.png';
import image79Url from './Lechuck.png';
import image80Url from './Life Saver.png';
import image81Url from './Lion Fish.png';
import image82Url from './Lobster.png';
import image83Url from './Loch Ness.png';
import image84Url from './Manatee.png';
import image85Url from './Map.png';
import image86Url from './Martini.png';
import image87Url from './Mermaid 1.png';
import image88Url from './Mermaid 2.png';
import image89Url from './Mermaid 3.png';
import image90Url from './Mermaid 4.png';
import image91Url from './Merman 1.png';
import image92Url from './Merman 2.png';
import image93Url from './Merman 3.png';
import image94Url from './Merman 4.png';
import image95Url from './Message in a bottle.png';
import image96Url from './Monkey.png';
import image97Url from './Monocular.png';
import image98Url from './Moon.png';
import image99Url from './Mosquito.png';
import image100Url from './Mouse.png';
import image101Url from './Mushroom.png';
import image102Url from './Narwhal.png';
import image103Url from './Oar.png';
import image104Url from './Octohooks.png';
import image105Url from './Octokitty.png';
import image106Url from './Octopus.png';
import image107Url from './Otter.png';
import image108Url from './Oyster.png';
import image109Url from './Package.png';
import image110Url from './Parrot 2.png';
import image111Url from './Parrot 3.png';
import image112Url from './Parrot-Blue.png';
import image113Url from './Parrot-Green.png';
import image114Url from './Parrot-Purple.png';
import image115Url from './Parrot-Red.png';
import image116Url from './Parrot that ran into a window.png';
import image117Url from './Peach.png';
import image118Url from './Pelican.png';
import image119Url from './Penguin.png';
import image120Url from './Pig.png';
import image121Url from './Pineapple.png';
import image122Url from './Piranha.png';
import image123Url from './Pirate Pride Flag.png';
import image124Url from './Pitaya.png';
import image125Url from './Plankton.png';
import image126Url from './Platypus.png';
import image127Url from './Pot.png';
import image128Url from './Puffer.png';
import image129Url from './Puffin.png';
import image130Url from './Rubber Chicken with a Pulley in the Middle.png';
import image131Url from './Ruby.png';
import image132Url from './Rum.png';
import image133Url from './Sea Anemone.png';
import image134Url from './Seagull.png';
import image135Url from './Seahorse.png';
import image136Url from './Shark.png';
import image137Url from './Sheep.png';
import image138Url from './Shell.png';
import image139Url from './Shrimp.png';
import image140Url from './Skeleton-Belt.png';
import image141Url from './Skeleton-Black.png';
import image142Url from './Skeleton-Naked.png';
import image143Url from './Skeleton-Red.png';
import image144Url from './Skeleton-Stripe.png';
import image145Url from './Sloth.png';
import image146Url from './Snail.png';
import image147Url from './Snake.png';
import image148Url from './Spider.png';
import image149Url from './Squid.png';
import image150Url from './Starfish.png';
import image151Url from './Stingray.png';
import image152Url from './Surgeon Fish.png';
import image153Url from './Sushi.png';
import image154Url from './Taco.png';
import image155Url from './Technicolor Parrot.png';
import image156Url from './Telescope.png';
import image157Url from './Tiki Drink.png';
import image158Url from './Toucan.png';
import image159Url from './Treasure Chest.png';
import image160Url from './Turtle.png';
import image161Url from './Unicorn.png';
import image162Url from './Vampire Squid.png';
import image163Url from './Walrus.png';
import image164Url from './Watermelon.png';
import image165Url from './Whale.png';
import image166Url from './Yeti.png';
import image167Url from './Zebra.png';

export const avatars = {
  'Alien': image1Url,
  'Alpaca': image2Url,
  'Anchor': image3Url,
  'Anemone': image4Url,
  'Anglerfish': image5Url,
  'Banana': image6Url,
  'Barnacle': image7Url,
  'Barrel': image8Url,
  'Bat': image9Url,
  'Bee': image10Url,
  'Bird-Blue-Flapping': image11Url,
  'Bird-Blue': image12Url,
  'Bird-Green': image13Url,
  'Bird-Red': image14Url,
  'Blob Fish': image15Url,
  'Boar': image16Url,
  'Bomb': image17Url,
  'Boot': image18Url,
  'Bulldog': image19Url,
  'Bunny Pirate': image20Url,
  'Bunny': image21Url,
  'Buoy': image22Url,
  'Cactus': image23Url,
  'Cannon-Boom': image24Url,
  'Cannon': image25Url,
  'Cat': image26Url,
  'Chameleon': image27Url,
  'Characters-Felix': image28Url,
  'Characters-Jonas': image29Url,
  'Clam': image30Url,
  'Clown Fish': image31Url,
  'Cockroach': image32Url,
  'Coconut': image33Url,
  'Coil of Rope': image34Url,
  'Coin': image35Url,
  'Compass': image36Url,
  'Coral': image37Url,
  'Cow': image38Url,
  'Crab': image39Url,
  'Creature from the Black Lagoon': image40Url,
  'Crocodile': image41Url,
  'Cuttlefish': image42Url,
  'Daiquiri': image43Url,
  'Davy Jones': image44Url,
  'Devilfish': image45Url,
  'Diamond': image46Url,
  'Dinosaur': image47Url,
  'Dodo Bird': image48Url,
  'Dog': image49Url,
  'Dolphin': image50Url,
  'Dragon': image51Url,
  'Duck': image52Url,
  'Dynamite': image53Url,
  'Eagle': image54Url,
  'Earthworm': image55Url,
  'Eel': image56Url,
  'Elephant': image57Url,
  'Elvis': image58Url,
  'Emerald': image59Url,
  'Fantasia Sea Cucumber': image60Url,
  'Fish-Blue': image61Url,
  'Fish-Green': image62Url,
  'Fish Net': image63Url,
  'Fish-Pink': image64Url,
  'Fish-Yellow': image65Url,
  'Flamingo': image66Url,
  'Flounder': image67Url,
  'Frog': image68Url,
  'Gemstone': image69Url,
  'Ghost': image70Url,
  'Goblin Shark': image71Url,
  'Gorilla': image72Url,
  'Hammerhead Shark': image73Url,
  'Hermit Crab': image74Url,
  'Jelly Fish': image75Url,
  'Kangaroo': image76Url,
  'Killer Whale': image77Url,
  'Kraken': image78Url,
  'Lechuck': image79Url,
  'Life Saver': image80Url,
  'Lion Fish': image81Url,
  'Lobster': image82Url,
  'Loch Ness': image83Url,
  'Manatee': image84Url,
  'Map': image85Url,
  'Martini': image86Url,
  'Mermaid 1': image87Url,
  'Mermaid 2': image88Url,
  'Mermaid 3': image89Url,
  'Mermaid 4': image90Url,
  'Merman 1': image91Url,
  'Merman 2': image92Url,
  'Merman 3': image93Url,
  'Merman 4': image94Url,
  'Message in a bottle': image95Url,
  'Monkey': image96Url,
  'Monocular': image97Url,
  'Moon': image98Url,
  'Mosquito': image99Url,
  'Mouse': image100Url,
  'Mushroom': image101Url,
  'Narwhal': image102Url,
  'Oar': image103Url,
  'Octohooks': image104Url,
  'Octokitty': image105Url,
  'Octopus': image106Url,
  'Otter': image107Url,
  'Oyster': image108Url,
  'Package': image109Url,
  'Parrot 2': image110Url,
  'Parrot 3': image111Url,
  'Parrot-Blue': image112Url,
  'Parrot-Green': image113Url,
  'Parrot-Purple': image114Url,
  'Parrot-Red': image115Url,
  'Parrot that ran into a window': image116Url,
  'Peach': image117Url,
  'Pelican': image118Url,
  'Penguin': image119Url,
  'Pig': image120Url,
  'Pineapple': image121Url,
  'Piranha': image122Url,
  'Pirate Pride Flag': image123Url,
  'Pitaya': image124Url,
  'Plankton': image125Url,
  'Platypus': image126Url,
  'Pot': image127Url,
  'Puffer': image128Url,
  'Puffin': image129Url,
  'Rubber Chicken with a Pulley in the Middle': image130Url,
  'Ruby': image131Url,
  'Rum': image132Url,
  'Sea Anemone': image133Url,
  'Seagull': image134Url,
  'Seahorse': image135Url,
  'Shark': image136Url,
  'Sheep': image137Url,
  'Shell': image138Url,
  'Shrimp': image139Url,
  'Skeleton-Belt': image140Url,
  'Skeleton-Black': image141Url,
  'Skeleton-Naked': image142Url,
  'Skeleton-Red': image143Url,
  'Skeleton-Stripe': image144Url,
  'Sloth': image145Url,
  'Snail': image146Url,
  'Snake': image147Url,
  'Spider': image148Url,
  'Squid': image149Url,
  'Starfish': image150Url,
  'Stingray': image151Url,
  'Surgeon Fish': image152Url,
  'Sushi': image153Url,
  'Taco': image154Url,
  'Technicolor Parrot': image155Url,
  'Telescope': image156Url,
  'Tiki Drink': image157Url,
  'Toucan': image158Url,
  'Treasure Chest': image159Url,
  'Turtle': image160Url,
  'Unicorn': image161Url,
  'Vampire Squid': image162Url,
  'Walrus': image163Url,
  'Watermelon': image164Url,
  'Whale': image165Url,
  'Yeti': image166Url,
  'Zebra': image167Url,
};

export type AvatarValue = keyof typeof avatars;
export default avatars;
