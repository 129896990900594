import { Action, AnyAction, Dispatch, Middleware, Store } from 'redux';

export type ActionListener<A extends Action = AnyAction, S = any> = (action: A, dispatch: Dispatch<A>, store: Store<S, A>) => void;
export type StoreListener<A extends Action = AnyAction, S = any> = {
  [T in A['type']]?: ActionListener<Extract<A, { type: T }>, S>;
}

export default (listeners: StoreListener[]): Middleware => (store) => (next) => (action) => {
  // Listeners are provided with a picture of the world before the action is applied
  const preActionState = store.getState();

  // Release the action to reducers before firing additional actions
  next(action);

  // Always async
  setTimeout(() => {
    // We can have multiple listeners listening against the same action.type
    listeners.forEach((listener) => {
      const actionListener = listener[action.type];

      if (actionListener) {
        actionListener(action, store.dispatch, preActionState);
      }
    });
  });
};
