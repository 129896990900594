import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { avatars, AvatarValue } from '../assets/avatars';
import { SocketContext } from '../services/poker';
import { actions as pokerActions } from '../store/poker';
import AvatarSelect from './AvatarSelect';
import './joinForm.css';

export default function JoinForm() {
  const dispatch = useDispatch();
  const {
    name: defaultName,
    avatar: defaultAvatar,
    autoJoin: autoJoinEnabled,
    connected,
  } = useSelector((state) => state.poker);
  const socketService = useContext(SocketContext);
  const [name, setName] = useState(defaultName || '');
  const [avatar, setAvatar] = useState<AvatarValue>(defaultAvatar as AvatarValue);
  const [autoJoin, setAutoJoin] = useState(false);

  useEffect(() => {
    if (autoJoinEnabled && connected) {
      const validAvatars = Object.keys(avatars);
      if (name && avatar && validAvatars.includes(avatar)) {
        socketService?.join(name, avatar);
        dispatch(pokerActions.join(name, avatar));
      } else {
        dispatch(pokerActions.disableAutoJoin());
      }
    }
  }, [dispatch, socketService, autoJoinEnabled, connected, name, avatar]);

  if (autoJoinEnabled) {
    return <div className="joiningMessage">Joining ...</div>;
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        socketService?.join(name, avatar!);
        dispatch(pokerActions.join(name, avatar!));

        if (autoJoin) {
          dispatch(pokerActions.enableAutoJoin());
        } else {
          dispatch(pokerActions.disableAutoJoin());
        }
      }}
    >
      <h2>Your Name</h2>
      <input
        type="text"
        name="name"
        value={name}
        onChange={(event) => setName(event.target.value)}
        required
      />

      <h2>Select Avatar</h2>
      <AvatarSelect value={avatar} onChange={setAvatar} required />

      <button className="primary" type="submit" disabled={!connected}>
        Join Session
      </button>

      <label className="label spacingLeft">
        <input
          type="checkbox"
          name="autojoin"
          checked={autoJoin}
          onChange={(event) => setAutoJoin(event.target.checked)}
        />
        Auto-Join
      </label>
    </form>
  );
}
