import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { SocketContext } from '../services/poker';
import { actions as pokerActions } from '../store/poker';
import './leaveButton.css';

export default function LeaveButton() {
  const dispatch = useDispatch();
  const socketService = useContext(SocketContext);

  return (
    <div
      className="leaveButton"
      onClick={() => {
        socketService?.leave();
        dispatch(pokerActions.leave());
      }}
    >
      <span className="icon">←</span>
      <span className="text">Leave</span>
    </div>
  );
}
