import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore';
import getSocketService, { SocketContext } from './services/poker';
import App from './components/App';
import 'normalize.css';
import './index.css';
import './fonts/font-faces.css';

const store = configureStore();
const socketService = getSocketService(store);

socketService.openConnection();

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store} context={ReactReduxContext}>
      <SocketContext.Provider value={socketService}>
        <App />
      </SocketContext.Provider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
